import { useEffect, useState } from 'react';
import './WorkInfo.css';
import WorkInfoQuadrant from './WorkInfoQuadrant';

function WorkInfo(props) {
  const [selectedTeeth, setSelectedTeeth] = useState(props.selectedTeeth ?? {});
  const containerStyle = {
    width: `${(props.size ?? 15)*16}px`,
  };

  useEffect(() => {
    setSelectedTeeth(props.selectedTeeth ?? {})
  }, [props.selectedTeeth]);

  const handleTeethSelection = (event) => {
    const data = event.detail;
    setSelectedTeeth((obj) => {
      obj[data.quadrant] = data.selectedTeeth;
      return obj;
    });
    props.onTeethSelect?.({
      detail: selectedTeeth,
    });
  }

  // Teeth quadrant structure
  //    1   |   2
  // -------|-------
  //    4   |   3
  const quadrantDetail = [[1,2],[4,3]];

  return (
    <div key={props.selectedTeeth} className={`container-fluid p-0 ${props.className ?? ''}`} style={containerStyle}>
      {
        quadrantDetail.map((row, index) => (
          <div key={index} className={`row p-0 ${index === 0 ? 'border-bottom' : 'border-top'}`} style={{whiteSpace: 'nowrap'}}>
            {
              row.map((quadNum) => (
                <WorkInfoQuadrant key={quadNum} quadrant={quadNum} size={props.size} mode={props.mode} teeth={selectedTeeth[quadNum]} onTeethSelect={handleTeethSelection} />
              ))
            }
          </div>
        ))
      }
    </div>
  );
}

export default WorkInfo;
