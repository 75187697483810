import PlusIconButton from '../../components/Button/PlusIconButton';
import { useDentistApi } from '../../services/api/dentist';
import { useInfiniteQuery } from 'react-query';
import { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import SaveDentist from './SaveDentist';
import Container from '../../components/Container';
import TableActionButton from '../../components/Button/TableActionButton';
import { BlobProvider } from '@react-pdf/renderer';
import DentistAddress from '../../pdfs/DentistAddress';

function Dentist() {
  const DentistApi = useDentistApi();
  const [recordId, setRecordId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  
  const { data: dentistData, isLoading, refetch, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfiniteQuery('dentist-list', async ({pageParam}) => {
    return DentistApi.search(null, null, pageParam).then(res => {
      if (res.status === 'success') {
        return res.result;
      }
    })
  }, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPageToken;
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const closeModal = () => {
    setRecordId(null);
    setShowModal(false);
  }
  
  const editRecord = (recId) => {
    setRecordId(recId);
    setShowModal(true);
  }

  const handleRecordSave =(rec) => {
    refetch();
    closeModal();
  }

  if (isLoading) {
    return <></>;
  }

  function renderData(dataList) {
    return (
      dataList?.map((rec) => (
        <tr key={rec.id} className="rounded-row">
          <th scope="row">{`${rec.firstName} ${rec.lastName}`}</th>
          <td>{rec.address}</td>
          <td>{rec.totalOrder ?? 0}</td>
          <td>{rec.modifiedAt}</td>
          <td style={{textAlign: 'right'}}>
            <div className="dropdown">
              <TableActionButton />
              <ul className="dropdown-menu dropdown-menu-end">
                <li><button className="dropdown-item" onClick={() => {editRecord(rec.id)}}>Edit</button></li>
                <li>
                  <BlobProvider document={<DentistAddress record={rec} />}>
                    {({ url, blob }) => (
                      <a href={url} target="_blank" rel="noreferrer" className='dropdown-item'>
                        Print
                      </a>
                    )}
                  </BlobProvider>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      ))
    )
  }

  return (
    <>
      <Container>
        <div className='container-fluid pt-1'>
          <div className="row">
            <div className="col-12">
              <h4>Dentists</h4>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className='d-flex justify-content-between'>
                <div>
                </div>
                <div>
                  <PlusIconButton className="fs-1" onClick={() => {setShowModal(true)}} />
                </div>
              </div>
            </div>
          </div>
          <table className="table collapsed-table table-hover">
            <thead className='sticky-top'>
              <tr className="rounded-row">
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col" style={{width: '150px'}}>Total Orders</th>
                <th scope="col" style={{width: '300px'}}>Modified at</th>
                <th scope="col" style={{width: '80px'}}>Options</th>
              </tr>
            </thead>
            <tbody>
              {
                dentistData.pages?.map((page) => renderData(page?.listOfItems))
              }
            </tbody>
          </table>
          {
            hasNextPage ?
              <div className='text-center'>
                <button className='btn btn-light border' onClick={fetchNextPage}>Load More</button>
              </div>
              : <></>
          }
          <div className='text-center'>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        </div>
      </Container>
      { showModal &&
        <Modal id='myModal' title={`${recordId ? 'Edit' : 'New'} Dentist`} onClose={closeModal}>
          <SaveDentist recordId={recordId} onSave={handleRecordSave} onCancel={closeModal} />
        </Modal>
      }
    </>
  );
}

export default Dentist;
