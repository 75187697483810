import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    marginTop: 10,
    marginLeft: 10,
  },
  section: {
    marginBottom: 5,
    display: 'block',
  },
});

export default function DentistAddress({record}) {
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <Text>From</Text>
        </View>
        <View style={styles.section}>
          <Text>{`${record.firstName} ${record.lastName}`}</Text>
        </View>
        <View style={styles.section}>
          <Text>{record.address}</Text>
        </View>
      </Page>
    </Document>
  )
}