import { useEffect } from "react";
import { useState } from "react";

export default function SearchInput({id, className, name, value: propValue, placeholder, onChange, debounceTime}) {
  const [prevValue, setPrevValue] = useState(propValue);
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (value !== prevValue) {
        setPrevValue(value);
        onChange({
          target: {
            value: value,
            name: name,
          }
        });
      }
    }, (debounceTime || 300));

    return () => clearTimeout(debounceTimer);
  }, [prevValue, value, name, onChange, debounceTime]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return <input type="text" id={id} className={className} name={name} value={value} onChange={handleChange} placeholder={placeholder} />;
}