import WorkInfoBox from './WorkInfoBox';
import './WorkInfo.css';
import { useEffect, useState } from 'react';

function WorkInfoQuadrant(props) {
  const [selectedTeeth, setSelectedTeeth] = useState(new Set(props.teeth ?? []));
  const quadrantNum = props.quadrant;
  
  // Teeth quadrant structure
  //     1    |    2
  // 87654321 | 12345678
  // ---------|---------
  // 87654321 | 12345678
  //     4    |    3
  const teethArr = quadrantNum === 1 || quadrantNum === 4 ? [8,7,6,5,4,3,2,1] : [1,2,3,4,5,6,7,8];
  const borderClass = quadrantNum === 1 || quadrantNum === 4 ? 'border-end' : 'border-start';

  useEffect(() => {
    setSelectedTeeth(new Set(props.teeth ?? []))
  }, [props.teeth]);

  const handleToothSelection = (event) => {
    const data = event.detail;
    if (data.isSelected) {
      selectedTeeth.add(data.tooth);
      setSelectedTeeth(existingSet => {existingSet.add(data.tooth); return existingSet;});
    } else {
      selectedTeeth.delete(data.tooth);
      setSelectedTeeth(existingSet => {existingSet.delete(data.tooth); return existingSet});
    }
    props.onTeethSelect?.({
      detail: {
        quadrant: quadrantNum,
        selectedTeeth: Array.from(selectedTeeth),
      },
    });
  }

  return (
    <div className={`${props.className ?? ''} ${borderClass} col-6 p-0 py-1 justify-content-center align-items-center d-flex`}>
      {
        teethArr.map((number) => (
          <WorkInfoBox key={number} toothNumber={number} size={props.size} mode={props.mode} isSelected={selectedTeeth.has(number)} onToothSelect={handleToothSelection} />
        ))
      }
    </div>
  );
}

export default WorkInfoQuadrant;
