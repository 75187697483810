export default function Modal({ id: modalId, title, onClose, children }) {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="modal fade show" id={modalId} style={{ display: 'block' }} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" onClick={() => {onClose?.()}}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" onClick={handleModalClick}>
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {onClose?.()}} aria-label="Close"></button>
            </div>
            {children}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={() => {onClose?.()}}></div>
    </>
  );
}
