import { useApi } from "./api.helper";

export function useUserApi() {
  const api = useApi();

  return {
    getMyProfile: () => {
      return api.get('/v1/users/me');
    },
    resetPassword: (oldPassword, newPassword) => {
      return api.post('/v1/users/me/resetPassword', {
        currentPassword: oldPassword,
        newPassword,
      });
    },
  }
}