import { useEffect, useState } from "react";

function WorkInfoBox(props) {
  const [isChecked, setIsChecked] = useState(props.isSelected ?? false);

  useEffect(() => {
    setIsChecked(props.isSelected ?? false)
  }, [props.isSelected]);
  
  const isEdit = props.mode === 'edit';

  const workInfoBoxStyle = {
    width: `${props.size ?? '15'}px`,
    height: `${props.size ?? '15'}px`,
    cursor: `${isEdit ? 'pointer': 'auto'}`,
  };


  const handleToothSelection =(event) => {
    setIsChecked(event.target.checked);
    props.onToothSelect?.({
      detail: {
        tooth: props.toothNumber,
        isSelected: event.target.checked,
      },
    });
  }

  if (isEdit) {
    return (
      <label className="workinfo-checkbox-container" style={workInfoBoxStyle}>
        <input type="checkbox" name={`tooth-${props.toothNumber}`} checked={isChecked} onChange={handleToothSelection} />
        <span className="checkbox-custom"></span>
        <span className="checkbox-number">{props.toothNumber}</span>
      </label>
    );
  } else {
    return (
      <label className="workinfo-checkbox-container" style={workInfoBoxStyle}>
        <span className={`checkbox-custom ${isChecked ? 'checked' : ''}`}></span>
        <span className="checkbox-number">{props.toothNumber}</span>
      </label>
    );
  }
}
export default WorkInfoBox;
