import { useNavigate, useParams } from 'react-router-dom';
import RoundedBox from '../../components/Box/RoundedBox'
import WorkInfo from '../../components/WorkInfo/WorkInfo';
import { useState } from 'react';
import { useQuery } from 'react-query';
import LookupInput from '../../components/Input/LookupInput';
import { convertDateToYYYYMMMDD, getFullName } from '../../helpers/utility';
import { useOrderApi } from '../../services/api/order.api';
import { toast } from "react-toastify";
import Container from '../../components/Container';

function SaveOrder() {
  const OrderApi = useOrderApi();
  const navigate = useNavigate();
  const { orderNum } = useParams();
  console.log(`${orderNum ? 'edit order' : 'new order'}`);
  const [formData, setFormData] = useState({
    orderNum: '',
    orderDate: convertDateToYYYYMMMDD(new Date()),
    dentist: {},
    workType: '',
    workInfo: {},
    shade: '',
    dispatchDate: '',
    patientName: '',
    description: '',
  });

  const { isLoading, isError } = useQuery(['order-rec', orderNum], async () => {
    if (!orderNum) {
      return;
    }
    return OrderApi.get(orderNum).then(res => {
      if (res.status === 'success') {
        setFormData({
          id: res.result.id,
          orderDate: res.result.orderDate,
          dentist: {
            id: res.result.dentist.id,
            label: getFullName(res.result.dentist.firstName, res.result.dentist.lastName),
          },
          workType: res.result.workType,
          workInfo: res.result.workInfo,
          shade: res.result.shade,
          dispatchDate: res.result.dispatchDate,
          patientName: res.result.patientName,
          description: res.result.description,
        });
        return res.result;
      } else {
        throw new Error(res.message);
      }
    })
  }, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const handleTeethSelection = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      'workInfo': event.detail,
    }));
  };

  const handleDentistSelection = (data) => {
    setFormData((prevState) => ({
      ...prevState,
      'dentist': data,
    }));
  };
  
  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    let promise;
    if (formData.id) {
      promise = OrderApi.edit(formData.id, formData);
    } else {
      promise = OrderApi.create(formData);
    }
    promise.then((res) => {
      if (res.status === 'success') {
        toast.success('Saved successfully');
        navigate('/orders/list');
      } else {
        toast.error(res.message);
      }
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  if(isLoading) return <></>

  if (isError) {
    toast.error('Error while editing rec');
    return <></>
  }
  console.log(formData);

  return (
    <Container grid="9" align='center'>
      <div className='container-fluid pt-1'>
        <div className="row">
          <div className="col-12 text-center">
            <h3>{orderNum ? 'Edit' : 'New'} Order</h3>
          </div>
        </div>
        <hr className='mb-0' />
        <form onSubmit={handleFormSubmit}>
          <div className='row'>
            <div className="col-6 mt-3">
              <label htmlFor="order_date" className="form-label">Order date</label>
              {
                orderNum
                  ? <input type="date" value={formData.orderDate} className="form-control" placeholder="Order date" disabled />
                  : <input type="date" id="order_date" name='orderDate' value={formData.orderDate} onChange={handleInput} className="form-control" placeholder="Order date" />
              }
            </div>
            <div className="col-6 mt-3">
              <label htmlFor="dentist" className="form-label">Dentist name</label>
              <LookupInput key={formData.dentist} id="dentist" name="dentist" value={formData.dentist} onSelect={handleDentistSelection} className="form-control" placeholder="Dentist" />
            </div>
            <div className="col-6 mt-3">
              <label htmlFor="work_type" className="form-label">Work type</label>
              <input type="text" id="work_type" name='workType' value={formData.workType} onChange={handleInput} className="form-control" placeholder="Work type" />
            </div>
            <div className="col-6 mt-3">
              <label htmlFor="shade" className="form-label">Shade</label>
              <input type="text" id="shade" name='shade' value={formData.shade} onChange={handleInput} className="form-control" placeholder="Shade" />
            </div>
          </div>
          <div className='row'>
            <div className="col-12 mt-3">
              <label className="form-labe mb-1">Work info</label>
              <div className='ps-2'>
                <WorkInfo key={formData.workInfo} className="m-0" size='50' mode="edit" selectedTeeth={formData.workInfo} onTeethSelect={handleTeethSelection} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-6 mt-3">
              <label htmlFor="dispatch_date" className="form-label">Dispatch date</label>
              <input type="date" id="dispatch_date" name='dispatchDate' value={formData.dispatchDate} onChange={handleInput} className="form-control" placeholder="Dispatch date" />
            </div>
            <div className="col-6 mt-3">
              <label htmlFor="patient_name" className="form-label">Patient name</label>
              <input type="text" id="patient_name" name='patientName' value={formData.patientName} onChange={handleInput} className="form-control" placeholder="Patient name" />
            </div>
            <div className="col-12 mt-3">
              <label htmlFor="description" className="form-label">Description</label>
              <textarea id="description" name='description' value={formData.description} onChange={handleInput} className="form-control" placeholder="Description" rows={3}></textarea>
            </div>
          </div>
          <hr />
          <div className='row mt-3'>
            <div className='col gap-2 justify-content-center d-flex'>
              <button className='btn btn-outline-danger' type='button' onClick={() => {navigate('/orders/list')}}>Cancel</button>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default SaveOrder;
