import { useApi } from "./api.helper";

export function useAuthApi() {
  const api = useApi();

  return {
    login: (email, password) => {
      return api.post('/v1/auth/login', {
        email,
        password,
      });
    },
    signout: () => {
      return api.post('/v1/auth/signout');
    },
  }
}
