import { useState } from 'react';
import { useDentistApi } from '../../services/api/dentist';
import { toast } from 'react-toastify';
import { useQuery } from "react-query";

export default function SaveDentist({recordId, onCancel, onSave}) {
  console.log(recordId);
  const DentistApi = useDentistApi();
  const [formData, setFormData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    address: '',
  });

  const { isLoading, isError } = useQuery(['dentist-rec', recordId], async () => {
    if (!recordId) {
      return;
    }
    return DentistApi.get(recordId).then(res => {
      if (res.status === 'success') {
        setFormData({
          id: res.result.id,
          firstName: res.result.firstName,
          lastName: res.result.lastName,
          address: res.result.address,
        });
        return;
      } else {
        throw new Error(res.message);
      }
    })
  }, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  
  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleFormSubmit = (event) => {
    event.preventDefault();

    let promise;
    if (formData.id) {
      promise = DentistApi.edit(formData.id, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
      });
    } else {
      promise = DentistApi.create({
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
      });
    }
    promise.then((res) => {
      if (res.status === 'success') {
        onSave?.(res.result);
        toast.success('Saved successfully');
      } else {
        toast.error(res.message);
      }
    }).catch((err) => {
      toast.error(err.message);
    })
  };

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    toast.error('Error while editing rec');
    onCancel?.();
  }

  return (
    <div className="bg-body-tertiary">
      <form onSubmit={handleFormSubmit}>
        <div className='container-fluid'>
          <div className='row'>
            <div className="col-6 mt-3">
              <label htmlFor="first_name" className="form-label">First Name</label>
              <input type="text" id="first_name" name='firstName' value={formData.firstName} onChange={handleInput} className="form-control" placeholder="First Name" />
            </div>
            <div className="col-6 mt-3">
              <label htmlFor="last_name" className="form-label">Last Name</label>
              <input type="text" id="last_name" name='lastName' value={formData.lastName} onChange={handleInput} className="form-control" placeholder="First Name" />
            </div>
            <div className="col-12 mt-3">
              <label htmlFor="address" className="form-label">Address</label>
              <textarea id="address" name='address' value={formData.address} onChange={handleInput} className="form-control" placeholder="Address" rows={3}></textarea>
            </div>
          </div>
        </div>
        <hr />
        <div className='container-fluid'>
          <div className='row my-2'>
            <div className='col gap-2 justify-content-between d-flex'>
              <button className='btn btn-outline-danger' type='button' onClick={() => {onCancel?.()}}>Cancel</button>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
