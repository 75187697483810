import { useEffect, useState } from "react";
import { useDentistApi } from "../../services/api/dentist";
import SearchInput from "./SearchInput";
import { useQuery } from "react-query";
import './LookupInput.css';
import Pill from "../Pill";

export default function LookupInput({id, name, value: propValue, className, placeholder, onSelect}) {
  const DentistApi = useDentistApi();
  const [term, setTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const { data, isLoading, isError, refetch } = useQuery(['dentist', term], async () => {
    if (term) {
      return DentistApi.search(term, 5).then((res) => {
        if (res.status === 'success') {
          return res.result;
        }
      })
    } else {
      return {
        listOfItems: [],
      };
    }
  }, {
    enabled: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const handleOnChange = (event) => {
    setTerm(event.target.value);
  };
  
  const hideDropdown = () => {
    setShowDropdown(false);
  };
  const unhideDropdown = () => {
    setShowDropdown(true);
  };

  const selectRec = (event) => {
    const ele = event.target;
    const id = ele.getAttribute('data-id');
    const label = ele.getAttribute('data-label');
    setValue({
      id,
      label,
    });
    onSelect?.({
      id,
      label,
    });
  }
  const clearSelectedValue = () => {
    setTerm('');
    setValue({});
    onSelect?.({});
  }

  if (value.id) {
    return (
      <>
      <Pill key={value.id} className={className} label={value.label || value.id} onClose={clearSelectedValue}/>
      </>
    );
  }

  return (
    <>
      <div className="search-lookup position-relative" onBlur={hideDropdown} onFocus={unhideDropdown}>
        <SearchInput id={id} name={name} value={term} onChange={handleOnChange} className={className} placeholder={placeholder} />
        { showDropdown && !!term &&
          <ul className="list-group position-absolute w-100 shadow-sm border" style={{zIndex: 1100}}>
            {
              data?.listOfItems?.map((rec) => (
                <li key={rec.id} data-id={rec.id} data-label={`${rec.firstName} ${rec.lastName}`} className="list-group-item border-0 text-truncate" onMouseDown={selectRec}>
                  <b>{rec.firstName} {rec.lastName}</b>
                  <br/>
                  {rec.address}
                </li>
              ))
            }
          </ul>
        }
      </div>
    </>
  );
}