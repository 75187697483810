import { useApi } from "./api.helper";

export function useDentistApi() {
  const api = useApi();

  return {
    search: (term, pageSize, nextPageToken) => {
      const queryParams = [];
      term && queryParams.push(`term=${term}`);
      pageSize && queryParams.push(`limit=${pageSize}`);
      nextPageToken && queryParams.push(`nextPageToken=${nextPageToken}`);
      let params = '';
      if (queryParams.length) {
        params = queryParams.join('&');
      }
      return api.get(`/v1/dentists${params ? '?' + params : ''}`);
    },
    get: (recordId) => {
      return api.get(`/v1/dentists/${recordId}`);
    },
    create: (data) => {
      return api.post('/v1/dentists', {
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
      });
    },
    edit: (recordId, data) => {
      return api.put(`/v1/dentists/${recordId}`, {
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
      });
    },
  }
}