import React from 'react';

export default function Pill({ className, label, onClose }) {
  return (
    <div className={`d-flex justify-content-between border rounded-2 ${className ?? ''}`}>
      <span>
        {label}
      </span>
      <button type="button" className="btn-close ms-1" aria-label="Close" onClick={onClose}></button>
    </div>
  );
};