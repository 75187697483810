import { Link, useParams } from 'react-router-dom';
import WorkInfo from '../../components/WorkInfo/WorkInfo';
import { useQuery } from 'react-query';
import { getFullName } from '../../helpers/utility';
import { useOrderApi } from '../../services/api/order.api';
import { toast } from "react-toastify";
import Container from '../../components/Container';
import { Margin, usePDF } from 'react-to-pdf';

export default function ViewOrder() {
  const { toPDF, targetRef } = usePDF({
    method: 'open',
    filename: 'order-report.pdf',
    page: {
      margin: Margin.SMALL,
    },
  });
  const OrderApi = useOrderApi();
  const { orderNum } = useParams();
  if (!orderNum) {
    toast.error('no order to view');
    return <></>
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: orderRec, isLoading, isError } = useQuery(['order-rec', orderNum], async () => {
    return OrderApi.get(orderNum).then(res => {
      if (res.status === 'success') {
        return res.result;
      } else {
        throw new Error(res.message);
      }
    })
  }, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  if(isLoading) return <></>

  if (isError) {
    toast.error('Error while viewing rec');
    return <></>
  }

  return (
    <Container grid="8">
      <div className='container-fluid pt-1' ref={targetRef}>
        <div className="row justify-content-between align-items-end">
          <div className="col-6">
            <h6 className='text-secondary mb-0'>Order</h6>
            <h3 className='mb-0'>{orderRec.id}</h3>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <div className='d-flex gap-2'>
              <button className='btn btn-light border' onClick={() => toPDF()} title='Print'><i className='bi bi-printer'></i></button>
              <Link className='btn btn-outline-primary' to={`/orders/${orderRec.id}/edit`}>Edit</Link>
            </div>
          </div>
        </div>
        <hr className='mb-0' />
        <div className='row'>
          <div className="col-6 mt-3">
            <label className="text-secondary fs-7">Order date</label>
            <div>{orderRec.orderDate}</div>
          </div>
          <div className="col-6 mt-3">
            <label className="text-secondary fs-7">Dentist name</label>
            <div>{getFullName(orderRec.dentist.firstName, orderRec.dentist.lastName)}</div>
          </div>
          <div className="col-6 mt-3">
            <label className="text-secondary fs-7">Work type</label>
            <div>{orderRec.workType}</div>
          </div>
          <div className="col-6 mt-3">
            <label className="text-secondary fs-7">Shade</label>
            <div>{orderRec.shade}</div>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 mt-3">
            <label className="text-secondary fs-7">Work info</label>
            <div className='ps-2'>
              <WorkInfo className="m-0" size='49' selectedTeeth={orderRec.workInfo} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className="col-6 mt-3">
            <label className="text-secondary fs-7">Dispatch date</label>
            <div>{orderRec.dispatchDate}</div>
          </div>
          <div className="col-6 mt-3">
            <label className="text-secondary fs-7">Patient name</label>
            <div>{orderRec.patientName}</div>
          </div>
          <div className="col-12 mt-3">
            <label className="text-secondary fs-7">Description</label>
            <div>{orderRec.description}</div>
          </div>
        </div>
        {
          orderRec.createdBy && orderRec.modifiedBy
          ? (
            <div className='row'>
              <div className="col-6 mt-3">
                <label className="text-secondary fs-7">Created by</label>
                <div>{getFullName(orderRec.createdBy.firstName, orderRec.createdBy.lastName)}</div>
                <div>{orderRec.createdAt}</div>
              </div>
              <div className="col-6 mt-3">
                <label className="text-secondary fs-7">Modified by</label>
                <div>{getFullName(orderRec.modifiedBy.firstName, orderRec.modifiedBy.lastName)}</div>
                <div>{orderRec.modifiedAt}</div>
              </div>
            </div>
          ) : <></>
        }
      </div>
    </Container>
  );
}
