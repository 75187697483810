import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthApi } from '../../services/api/auth';
import './Navbar.css';

const navTabs = [{
  label: 'Home',
  name: 'home',
  link: '/',
}, {
  label: 'Orders',
  name: 'orders',
  link: '/orders/list',
}, {
  label: 'Dentists',
  name: 'dentists',
  link: '/dentists',
}];

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const AuthApi = useAuthApi();

  const currentPath = location.pathname;
  let tabName = '';
  if (currentPath.startsWith('/orders')) {
    tabName = 'orders';
  } else if (currentPath.startsWith('/dentists')) {
    tabName = 'dentists';
  } else if (currentPath === '/') {
    tabName = 'home';
  }

  const username = window.Dentacamp?.CurrentUser?.firstName;

  const signout = () => {
    AuthApi.signout().then((res) => {
      if (res.status === 'success') {
        navigate('/login');
      } else {
        toast.error(res.message);
      }
    }).catch((e) => {});
  }

  return (
    <nav className="sticky-top navbar navbar-expand-lg navbar-dark bg-dark" style={{zIndex: 2000}}>
      <div className="container">
        <Link className="navbar-brand" to="/">Dentacamp</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {
              navTabs.map((tab) => (
                <li key={tab.name} className="nav-item px-lg-3">
                  <Link className={`nav-link ${tabName === tab.name ? 'active': ''}`} to={tab.link}>{tab.label}</Link>
                </li>
              ))
            }
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
              </button>
              <ul className="dropdown-menu dropdown-menu-lg-end">
                <li><p className='px-3'><strong>Hello {username}!</strong></p></li>
                <li><Link className='dropdown-item' to="/reset-password">Reset password</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><button className="dropdown-item" onClick={signout}><i className="bi bi-box-arrow-right"></i> Sign out</button></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
