import './App.css';
import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import OrderList from './pages/Order/Listing/OrderList';
import SaveOrder from './pages/Order/SaveOrder';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Login from './pages/Login/Login';
// import Home from './pages/Home/Home';
import Dentist from './pages/Dentist/Dentist';
import { setMyProfile } from './helpers/utility';
import { useUserApi } from './services/api/users';
import ViewOrder from './pages/Order/ViewOrder';

function App() {
  const UserApi = useUserApi();
  const isAppLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;
  
  useEffect(() => {
    console.log('currentPath--', currentPath);

    if (!isAppLoaded.current) {
      isAppLoaded.current = true;
      setMyProfile(UserApi).then(res => {
        if (res.success && currentPath.startsWith('/login')) {
          navigate('/');
        }
        setIsLoading(false);
      });
    }
  }, [UserApi, currentPath, navigate, setIsLoading]);

  if (isLoading) {
    return <></>
  }

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<OrderList />} />
        <Route path='/orders/list' element={<OrderList />} />
        <Route path='/orders/new' element={<SaveOrder />} />
        <Route path='/orders/:orderNum/edit' element={<SaveOrder />} />
        <Route path='/orders/:orderNum/view' element={<ViewOrder />} />
        <Route path='/dentists' element={<Dentist />} />
        <Route path='/reset-password' element={<ResetPassword />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
