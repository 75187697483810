import { toast } from "react-toastify";

export const setMyProfile = async (UserApi) => {
  return UserApi.getMyProfile()
  .then((res) => {
    if (res.status === 'success'){
      window.Dentacamp.CurrentUser = res.result;
    } else {
      toast.error(res.message);
    }
    return {
      success: res.status === 'success',
    }
  })
  .catch((err) => {
    return {
      success: false,
      message: err.message,
    }
  });
}

export const convertDateToYYYYMMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const getFullName = (firstName, lastName) => {
  return `${firstName ?? ''} ${lastName ?? ''}`.trim();
}