export default function Container({className, children, grid, align}) {
  const simplifyClassName = (classTxt = '') => {
    let color = ' bg-white';
    if (classTxt) {
      if (classTxt.includes('bg-')) {
        color = '';
      }
    }
    return `${classTxt}${color}`;
  }
  return (
    <div className='container mt-4'>
      <div className={`row ${align ? `justify-content-${align}` : ''}`}>
        <div className={`col-${grid || 12}`}>
          <div className={`p-3 ${simplifyClassName(className)}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}